/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Box, Circle, Heading, Icon, Text } from '@chakra-ui/react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const PowerfulCard = ({ icon, title, description }) => (
  <>
    <Box className='tilt' px={6} py={10} bg='white' borderRadius='md' shadow='lg'>
      <Box className='card-body py-5 px-4'>
        <Circle
          mb={4}
          bgGradient='linear(to-br, orange.500,pink.400)'
          className='icon-box rounded-circle gradient text-contrast shadow icon-xl mb-3'
        >
          <Icon as={icon} fill='gray.50' boxSize='9' />
        </Circle>

        <Heading as='h4' size='sm' mb='5'>
          {title}
        </Heading>

        <Text color='gray.500' fontSize='md'>
          {description}
        </Text>
      </Box>
    </Box>
  </>
);

export default PowerfulCard;
