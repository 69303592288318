import React from 'react';
import { Box, Button, Grid, GridItem, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { IoExtensionPuzzleSharp } from 'react-icons/io5';
import { SiReactrouter } from 'react-icons/si';
import { RiCoinsFill } from 'react-icons/ri';
import { GiJuggler } from 'react-icons/gi';
import { Slide } from 'react-awesome-reveal';
import NextLink from 'next/link';
import PowerfulCard from './PowerfulCard';

const createCards = () => {
  const features: any = [];
  const cards = [
    {
      class: 'mt-6 mt-6 mx-lg-auto',
      icon: SiReactrouter,
      title: 'Complex',
      animation: { delay: 0 },
      description: `Everyone wants to be healthier, but it's hard to know what vitamins and supplements you need 
                    without the help of a professional.`
    },

    {
      class: 'mx-lg-auto',
      icon: GiJuggler,
      title: 'Overwhelming',
      animation: { delay: 800 },
      description: 'Store shelves contain numerous vitamins and brands. How does one choose the right combination?'
    },

    {
      class: 'mr-lg-auto',
      icon: IoExtensionPuzzleSharp,
      title: 'Inconsistent',
      animation: { delay: 500 },
      description:
        'Your individual vitamin needs might require multiple bottles and containers, which must be opened daily and ' +
        'run out at different times.'
    },

    {
      class: 'mt-6n mr-lg-auto',
      icon: RiCoinsFill,
      title: 'Expensive',
      animation: { delay: 1200 },
      description:
        'Even after you deciphered your personal vitamin needs, a personalised vitamin programme with different tablets' +
        ' and pills of vitamins can be very costly.'
    }
  ];

  cards.forEach((el, i) => {
    // if (i % 2 === use) {
    // eslint-disable-next-line react/no-array-index-key
    features.push(<PowerfulCard {...el} key={i} />);
    // }
  });

  return features;
};

const PowerfulFeatures = () => (
  <Box
    as='section'
    className='alter3-features'
    id='why'
    maxW={{ base: 'xl', md: '7xl' }}
    mx='auto'
    px={{ base: '6', md: '8' }}
  >
    <Box display={{ base: 'none', md: 'block' }} className='shapes-container' zIndex={-1}>
      <Box className='shape shape-ring shape-ring-1'>
        <Box className='animation animation--rotating' />
      </Box>
      <Box className='shape shape-ring shape-ring-2'>
        <Box className='animation animation--rotating' />
      </Box>
      <Box className='shape shape-circle animation--clockwise'>
        <Box />
      </Box>

      <Box className='shape background-shape-main' zIndex='-100' />
    </Box>

    <Box>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(6, 1fr)' }} gap={6}>
        <GridItem colSpan={{ base: 1, lg: 2 }} zIndex='2'>
          <Box className='order-lg-last'>
            <Heading as='p' size='sm' color='orange.500' mb={4}>
              A fresh take on Vitamins
            </Heading>
            <Heading as='h2' size={{ base: 'md', md: 'lg' }} className='heading-line'>
              Vitamins don't have to be difficult
            </Heading>
            <Text>Vitamin Club is the answer.</Text>
            <Button as={NextLink} href='/quiz/' size='lg' color='white' colorScheme='orange' mt={8}>
              Complete a quick quiz
            </Button>
            <Image
              src='/images/sachets.webp'
              width='100%'
              height='100%'
              mt={20}
              loading='lazy'
              alt='Non-traditional vitamins'
              fallbackSrc='/images/sachets.png'
            />
          </Box>
        </GridItem>
        <GridItem colStart={{ base: 1, lg: 4 }} colEnd={{ base: 2, lg: 7 }}>
          <SimpleGrid spacing={6} columns={[1, 1, 1, 2]} className='rotated-cards'>
            <Slide direction='up' triggerOnce cascade damping={0.2}>
              {createCards()}
            </Slide>
          </SimpleGrid>
        </GridItem>
      </Grid>
    </Box>
  </Box>
);

export default PowerfulFeatures;
